import { Fragment } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import OrderArticle, { getTotalPrice } from "../../../../global/utils/models/order/OrderArticle";
import CurrencySelector from "../../../../global/components/CurrencySelector/CurrencySelector";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import Stock from "../Stock";
import useSalesareaType, { SalesareaType } from "../../../../global/utils/hooks/useSalesareaType";
import { useAppSelector } from "../../../../global/utils/redux/store";
import { selectArticleCampaignsMap } from "../../../../global/utils/redux/selectors/selectArticleCampaignsMap";
import { selectSalesAreaPriceLineId } from "../../../../global/utils/useSalesAreaPriceLineId.ts";
import FormatCurrency from "../../../../global/components/Currencies/FormatCurrency.tsx";

interface Props {
  localPrice: number;
  orderArticle: OrderArticle;
}

const currencyToLang: { [currency: string]: string } = {
  EUR: "NL",
  USD: "US",
  AUD: "AU",
  CAD: "CA",
  CHF: "CH",
  CNY: "CN",
  DKK: "DK",
  GBP: "GB",
  JPY: "JP",
  NOK: "NO",
  SEK: "SE",
  ZAR: "ZA",
};

export default function LocalPriceSalesPrice(props: Props) {
  const salesareaType = useSalesareaType();

  if (salesareaType === SalesareaType.CATALOG) {
    return <LocalPriceSalesPriceCatalogMode {...props} />;
  } else {
    return <LocalPriceSalesPriceOrderMode {...props} />;
  }
}

export function LocalPriceSalesPriceOrderMode(props: Props) {
  const orderArticle = props.orderArticle;
  const articleCampaignsMap = useAppSelector(selectArticleCampaignsMap);
  const isCampaignArticle = articleCampaignsMap[orderArticle.article.id]?.length > 0;
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);

  return (
    <Box
      sx={{ paddingX: 4, display: "flex", alignItems: "center", marginY: 3, justifyContent: "space-between", width: 1 }}
    >
      <CurrencySelector />
      <Box sx={{ width: 1 }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant={"h6"}
              sx={{ fontStyle: "italic", paddingX: 4, fontSize: "1em", textDecoration: "line-through" }}
              className={"JS-ArticleDialog-LocalPrice"}
            >
              <FormattedMessageJamezz id={"Local"} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant={"h6"}
              sx={{ fontStyle: "italic", paddingX: 4, fontSize: "1em", textDecoration: "line-through" }}
              className={"JS-ArticleDialog-LocalPrice"}
            >
              <FormatCurrency value={props.localPrice} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant={"h6"}
              sx={{ fontStyle: "italic", paddingX: 4, fontSize: "1em", color: isCampaignArticle ? "red" : null }}
              className={"JS-ArticleDialog-Price"}
            >
              <FormattedMessageJamezz id={"Airport Price"} />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant={"h6"}
              sx={{ fontStyle: "italic", paddingX: 4, fontSize: "1em", color: isCampaignArticle ? "red" : null }}
              className={"JS-ArticleDialog-Price"}
            >
              <FormatCurrency value={getTotalPrice(orderArticle, priceLineId, { count: 1 })} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Stock article={props.orderArticle.article} />
    </Box>
  );
}

// Special component for Kappe Schiphol
export function LocalPriceSalesPriceCatalogMode(props: Props) {
  const currency = useAppSelector((state) => state.menu.currency);
  const orderArticle = props.orderArticle;
  const articleCampaignsMap = useAppSelector(selectArticleCampaignsMap);
  const isCampaignArticle = articleCampaignsMap[orderArticle.article.id]?.length > 0;
  const currencyRates = useAppSelector((state) => state.menu.currencyRates);
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  return (
    <Box sx={{ paddingX: 4, alignItems: "center", marginY: 3, justifyContent: "space-between", width: 1 }}>
      <Box sx={{ width: 1 }}>
        <Grid container sx={{ maxWidth: 600 }} spacing={2}>
          {Object.keys(currencyRates)
            .sort((a, b) => {
              if (currencyRates[a].currency == currency) {
                return 1;
              }
              if (currencyRates[b].currency == currency) {
                return -1;
              }
              return a.localeCompare(b);
            })
            .map((currencyKey) => {
              const currencyInList = currencyRates[currencyKey];
              return (
                <Fragment key={currencyInList.currency}>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      fontWeight: currencyInList.currency === currency ? 600 : 100,
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {currencyInList.currency}
                  </Grid>
                  <Grid item xs={3} sx={{ display: "inline-flex", alignItems: "center" }}>
                    <span
                      style={{ width: 93, height: 70, border: "1px solid #00000077" }}
                      className={
                        "JS-LanguageFlag-" +
                        currencyToLang[currencyInList.currency] +
                        " fi fi-" +
                        (currencyToLang[currencyInList.currency] ?? currencyInList.currency).toLowerCase()
                      }
                    ></span>
                  </Grid>
                  <Grid item xs={6} sx={{ display: "inline-flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Typography
                      variant={"h6"}
                      sx={{
                        paddingX: 4,
                        fontSize: "1em",
                        color: isCampaignArticle ? "red" : null,
                        fontWeight: currencyInList.currency === currency ? 600 : 100,
                      }}
                      className={"JS-ArticleDialog-Price"}
                    >
                      <FormatCurrency value={getTotalPrice(orderArticle, priceLineId, { count: 1 })} />
                    </Typography>
                  </Grid>
                </Fragment>
              );
            })}
        </Grid>
      </Box>
      <Box sx={{ width: 1, display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Stock article={props.orderArticle.article} />
      </Box>
    </Box>
  );
}
