import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/redux/store";
import { MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import { setCurrency } from "../../utils/redux/menuSlice";
import FormatCurrency from "../Currencies/FormatCurrency.tsx";

interface Props {
  value?: number;
}

export default function CurrencySelector(props: Props) {
  const currency = useAppSelector((state) => state.menu.currency);
  const currencyRates = useAppSelector((state) => state.menu.currencyRates);
  const dispatch = useAppDispatch();

  const menuItems = useMemo(() => {
    return Object.keys(currencyRates)
      .sort()
      .map((currencyKey) => {
        return (
          <MenuItem value={currencyRates[currencyKey].currency} key={currencyRates[currencyKey].currency}>
            {currencyRates[currencyKey].currency} {props.value != null ? <FormatCurrency value={props.value} /> : null}
          </MenuItem>
        );
      });
  }, [currencyRates, props.value]);

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <Box sx={{ width: "auto", display: menuItems.length === 0 ? "none" : "unset" }}>
      <Select
        sx={{ paddingRight: 4 }}
        value={currency}
        onChange={(e) => {
          dispatch(setCurrency(e.target.value));
        }}
      >
        {menuItems}
      </Select>
    </Box>
  );
}
