import React, { useCallback, useEffect, useRef, useState } from "react";
import Article from "../models/menu/Article";
import store, { useAppDispatch, useAppSelector } from "../redux/store";
import isKiosk from "../helpers/isKiosk";
import SlideUpTransition from "../../components/SlideUpTransition";
import isQr from "../helpers/isQr";
import { useDialog } from "../dialog/DialogProvider";
import useIsWidescreen from "../useIsWidescreen";
import { ButtonBase, Paper, Stack, Typography, useTheme } from "@mui/material";
import { default as OrderArticleSelectorPageQr } from "../../../qr/pages/OrderArticleSelectorPage/OrderArticleSelectorPage";
import { default as OrderArticleSelectorPageKiosk } from "../../../kiosk/pages/OrderArticleSelectorPage/OrderArticleSelectorPage";
import Box from "@mui/material/Box";
import Image from "../../components/Image";
import FormattedMessageJamezz from "../../components/FormattedMessageJamezz";
import { getUpsellItems } from "./useDDAiData";
import { selectArticleHashNamesMap } from "../redux/selectors/selectArticleHashNamesMap";
import OrderPageQr from "../../../qr/pages/OrderPage/OrderPage";
import OrderPageKiosk from "../../../kiosk/pages/OrderPage/OrderPage";
import { ScrollShadows } from "@jamezz/react-components";
import ScrollShadowsQr from "../../../qr/components/ScrollShadowQr.tsx";

export default function DisplayAIUpsellOrderPage() {
  const dispatch = useAppDispatch();
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const aiUpsellData = useAppSelector((state) => state.aiData.currentUpsell);
  const articleHashNamesMap = useAppSelector(selectArticleHashNamesMap);
  const productsLoaded = useAppSelector((state) => state.menuData.productsLoaded ?? false);
  const products = useAppSelector((state) => state.menuData.products);
  const useBasketUpsell = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.useBaksetUpsell ?? true);
  const aiUpsellEnabled = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.enabled ?? false);
  const [aiUpsellProducts, setAiUpsellProducts] = useState<Article[]>([]);
  const items = useAppSelector((state) => state.shoppingCart.items);
  const max_suggestions = useAppSelector((state) => state.global.salesarea?.custom_data?.ai?.max_suggestions ?? 4);

  useEffect(() => {
    const articles = getUpsellItems(aiUpsellData, articleHashNamesMap, max_suggestions);
    if (aiUpsellData && productsLoaded) {
      setAiUpsellProducts(articles);
    }
  }, [dispatch, products, aiUpsellData, productsLoaded, articleHashNamesMap, items, max_suggestions]);

  const postActionAdding = useCallback(() => {
    const OrderPage = isQr() ? <OrderPageQr /> : <OrderPageKiosk />;
    openDialog({
      children: OrderPage,
      fullScreen: true,
      TransitionComponent: SlideUpTransition,
    });
  }, [openDialog]);
  const containerRef = useRef<HTMLDivElement>(null);
  const ScrollElement = isKiosk() ? ScrollShadows : ScrollShadowsQr;
  const theme = useTheme();

  const gap = isKiosk() ? 2 : 1;
  const widthOfShadow = isKiosk() ? 20 : 10;

  return aiUpsellEnabled &&
    useBasketUpsell &&
    aiUpsellProducts.length > 0 &&
    store.getState().shoppingCart.items.length > 0 ? (
    <Box>
      <Typography className={"JS-DisplayAIUpsellOrderPage-Title"}>
        <FormattedMessageJamezz id={"Others also bought"} />
      </Typography>
      <Stack
        direction={"row"}
        sx={{
          overflowX: "auto",
          gap,
          py: 1,
        }}
        alignItems={"stretch"}
        ref={containerRef}
      >
        <ScrollElement
          direction={"row"}
          containerRef={containerRef}
          styleTopShadow={{ marginLeft: `calc(-${theme.spacing(gap)} - ${widthOfShadow}px)` }}
          styleBottomShadow={{ marginLeft: `calc(-${theme.spacing(gap)} - ${widthOfShadow}px)` }}
        >
          {aiUpsellProducts.map((article: Article) => (
            <Paper
              key={article.hashedName}
              sx={{ flex: 1, borderRadius: 4, minWidth: isKiosk() ? 328 : isWidescreen ? 118 : 95 }}
            >
              <ButtonBase
                sx={{
                  width: 1,
                  height: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  p: 2,
                }}
                onClick={() => {
                  if (isKiosk()) {
                    openDialog({
                      children: (
                        <OrderArticleSelectorPageKiosk
                          article={article}
                          isUpselled={true}
                          upsellType={"AiUpsellCart"}
                          upsellSource={"CART"}
                          callAfterAdding={postActionAdding}
                        />
                      ),
                      fullScreen: "almostFullScreen",
                      TransitionComponent: SlideUpTransition,
                    });
                  } else if (isQr()) {
                    openDialog({
                      children: (
                        <OrderArticleSelectorPageQr
                          article={article}
                          isUpselled={true}
                          upsellType={"AiUpsellCart"}
                          upsellSource={"CART"}
                          callAfterAdding={postActionAdding}
                        />
                      ),
                      fullScreen: !isWidescreen,
                      TransitionComponent: SlideUpTransition,
                    });
                  }
                }}
              >
                <Image
                  srcSet={article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
                <Typography sx={{ fontWeight: 800, textAlign: "center" }}>{article.name}</Typography>
              </ButtonBase>
            </Paper>
          ))}
        </ScrollElement>
      </Stack>
    </Box>
  ) : null;
}
