import { useCallback, useEffect, useMemo, useState } from "react";
import CustomField from "../../../../types/shared/CustomField";
import {
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { differenceInCalendarDays, format, parse } from "date-fns";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import useCustomField from "./useCustomField";
import { httpGet } from "../../../../global/utils/api/http";
import { useIntl } from "react-intl";
import { useAppSelector } from "../../../../global/utils/redux/store";
import Box from "@mui/material/Box";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import { useCustomTexts } from "../../../../global/utils/useCustomTexts";
import isQr from "../../../../global/utils/helpers/isQr";

function NoTimeslotsAvailableDialog() {
  const { closeAllDialogs } = useDialog();
  const ct = useCustomTexts(["no timeslots available message"]);

  return (
    <Box data-cy="no-timeslots-available-dialog" sx={{ padding: 2 }}>
      <DialogTitle>
        <FormattedMessageJamezz id="Timeslots.no-timeslots-available.header" />
      </DialogTitle>
      <DialogContent>
        {ct["no timeslots available message"] ?? <FormattedMessageJamezz id="Timeslots.no-timeslots-available.body" />}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" sx={{ width: "100%" }} onClick={closeAllDialogs}>
          <FormattedMessageJamezz id="Timeslots.no-timeslots-available.view-menu" />
        </Button>
      </DialogActions>
    </Box>
  );
}

interface Props {
  customField: CustomField;
}

function CustomFieldDateTimeContent(props: Props) {
  const { openDialog } = useDialog();
  const customField = props.customField;
  const intl = useIntl();
  const { setCustomField } = useCustomField(props.customField);

  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState<Date | null>(null);
  const [dates, setDates] = useState<Date[]>([]);
  const [times, setTimes] = useState<Date[]>([]);
  const [timeslots, setTimeslots] = useState<Record<string, Date[]>>({});
  const [noSlotsAvailable, setNoSlotsAvailable] = useState(false);
  const items = useAppSelector((state) => state.shoppingCart.items);

  const maxWaitTime = items.reduce((carry, item) => {
    const waitTime = Number(item.article.apiData.waitTime);

    return waitTime > carry ? waitTime : carry;
  }, 0);

  const loadRemoteSlots = (retry = 0) => {
    httpGet(`/v5_2/session/getDeliverySlots?maxWaitTime=${maxWaitTime}`)
      .then((res: any) => {
        if (res.data.data) {
          /**
           * Display order slots
           */
          if (res.data.data.slotsAvail) {
            const tSlots: Record<string, Date[]> = {};
            const dList: Date[] = [];
            res.data.data.orderSlots.forEach((slotData: any) => {
              tSlots[slotData.orderDate] = [];
              dList.push(parse(slotData.orderDate, "yyyy-MM-dd", new Date()));
              slotData.orderTimes.forEach((timeSlot: any) => {
                const date = parse(slotData.orderDate + " " + timeSlot.orderTime, "yyyy-MM-dd HH:mm", new Date());
                tSlots[slotData.orderDate].push(date);
              });
            });

            setDates(dList);
            setTimeslots(tSlots);
          } else {
            setNoSlotsAvailable(true);
          }
        } else {
          /**
           * Failed ... retry ...
           */
          if (retry < 5) loadRemoteSlots(retry + 1);
        }
      })
      .catch(() => {
        /**
         * Failed ... retry ...
         */
        if (retry < 5) loadRemoteSlots(retry + 1);
      });
  };

  useEffect(() => {
    loadRemoteSlots();
  }, []);

  useEffect(() => {
    if (date) {
      const formatted = format(date, "yyyy-MM-dd");
      setTimes(timeslots[formatted]);
    } else {
      setTimes([]);
    }
  }, [date, timeslots]);

  const dateInUnix = useMemo(() => {
    // @ts-ignore
    return date?.getTime() ?? "";
  }, [date]);

  const timeInUnix = useMemo(() => {
    // @ts-ignore
    return time?.getTime() ?? "";
  }, [time]);

  useEffect(() => {
    /**
     * Format without timezone!
     */
    if (time != null) setCustomField(format(time as Date, "yyyy-MM-dd HH:mm:ss"));
  }, [setCustomField, time]);

  const formatDate = useCallback(
    (date: Date) => {
      const now = new Date();
      const diff = differenceInCalendarDays(date, now);
      const translatedDay = intl.formatMessage({ id: format(date, "iiii") });
      if (diff === 0) {
        return <FormattedMessageJamezz id={"Today"} />;
      } else if (diff === 1) {
        return <FormattedMessageJamezz id={"Tomorrow"} />;
      } else if (diff > 6) {
        return translatedDay + " - " + format(date, "dd LLL");
      } else {
        return translatedDay;
      }
    },
    [intl]
  );

  useEffect(() => {
    if (noSlotsAvailable && isQr()) {
      openDialog({
        children: <NoTimeslotsAvailableDialog />,
        disabledBackdropClick: true,
      });
    }
  }, [noSlotsAvailable, openDialog]);

  return (
    <>
      <FormControl fullWidth sx={{ marginY: 1 }}>
        <InputLabel className="JS-CustomFieldDateTimeContent-Label">
          <FormattedMessageJamezz id={"Pick a date"} />
        </InputLabel>
        <Select
          data-cy="timeslots-date-select"
          className="JS-CustomField-DateTime-Input"
          inputProps={{ required: customField.required }}
          value={dateInUnix}
          label={
            <span className="JS-CustomFieldDateTimeContent-Label">
              <FormattedMessageJamezz id={"Pick a date"} />
            </span>
          }
          onChange={(e) => {
            setDate(new Date(e.target.value as number));
            if (time) {
              setTime(null);
            }
          }}
          MenuProps={{ PaperProps: { sx: { maxHeight: "50%" } } }}
        >
          {dates.map((date, idx) => (
            <MenuItem
              data-cy={`timeslots-date-option-${idx}`}
              key={date.getTime()}
              value={date.getTime()}
              className="JS-CustomFieldDateContent-Option"
            >
              {formatDate(date)}
            </MenuItem>
          ))}
        </Select>

        <Collapse sx={{ width: 1 }} in={!!date}>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel className="JS-CustomFieldDateTimeContent-Label">
              <FormattedMessageJamezz id={"Pick a time"} />
            </InputLabel>
            <Select
              data-cy="timeslots-time-select"
              className="JS-CustomField-DateTime-Input"
              inputProps={{ required: customField.required }}
              disabled={!date}
              value={timeInUnix}
              label={<FormattedMessageJamezz id={"Pick a time"} />}
              onChange={(e) => {
                setTime(new Date(e.target.value as number));
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: "50%" } } }}
            >
              {times.map((date, idx) => (
                <MenuItem
                  data-cy={`timeslots-time-option-${idx}`}
                  value={date.getTime()}
                  className="JS-CustomFieldDateTimeContent-Option"
                >
                  {format(date, "HH:mm")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Collapse>
      </FormControl>
    </>
  );
}

export default CustomFieldDateTimeContent;
